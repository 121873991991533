import React, { useContext, useState } from "react";
import Context from "./context/Context";
import Web3 from "web3";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import liquidityAbi from '../../json/LiquidityToken.json';
import Button from "react-bootstrap-button-loader";
import { contract } from "../../hooks/constant";

const liquidityTokenFactoryAddr = "0x617Db0d7047132e4df904688641F43E89EbF44C5";
const routeraddress = "0xA39723dC2713C9eECe0CecC74AC519Aa2dFB4206";

export default function LiquidityToken(props) {
  const { createFee } = props;
  const history = useHistory();
  const { value, setValue } = useContext(Context);
  const [createloading, setCreateLoading] = useState(false);
  const [error, setError] = useState({
    name: "",
    symbol: "",
    supply: "",
    yieldFee: "",
    liquidityFee: "",
    charityAddr: "",
    charityFee: "",
  });

  const checkLiquidityTokenValidation = (input, inputValue) => {
    let terror = 0;
    let message = "";
    var reg;
    switch (input) {
      case "name":
        if (inputValue === "") {
          terror += 1;
          message = "Please Input Token Name!";
        } else {
          message = "";
        }
        break;
      case "symbol":
        if (inputValue === "") {
          terror += 1;
          message = "Please Input Token Symbol!";
        } else {
          message = "";
        }
        break;
      case "supply":
        inputValue = parseFloat(inputValue);
        reg = new RegExp(/^[+-]?\d+(\.\d+)?$/);
        if (!reg.test(inputValue) || parseFloat(inputValue) <= 0) {
          terror += 1;
          message = "Please Enter Valid Amount!";
        } else if (parseFloat(inputValue) <= 0) {
          terror += 1;
          message = "Supply Can Not Be Zero!";
        } else {
          message = "";
        }
        break;
      case "liquidityFee":
        inputValue = parseFloat(inputValue);
        reg = new RegExp(/^[+-]?\d+(\.\d+)?$/);
        if (!reg.test(inputValue) || parseFloat(inputValue) <= 0) {
          terror += 1;
          message = "Please Enter Valid Amount!";
        } else if (parseFloat(inputValue) <= 0) {
          terror += 1;
          message = "Liquidity Fee Can Not Be Zero!";
        } else {
          message = "";
        }
        break;
      case "yieldFee":
        inputValue = parseFloat(inputValue);
        reg = new RegExp(/^[+-]?\d+(\.\d+)?$/);
        if (!reg.test(inputValue) || parseFloat(inputValue) <= 0) {
          terror += 1;
          message = "Please Enter Valid Amount!";
        } else if (parseFloat(inputValue) <= 0) {
          terror += 1;
          message = "Yield Fee Can Not Be Zero!";
        } else {
          message = "";
        }
        break;
      case "charityFee":
        inputValue = parseFloat(inputValue);
        reg = new RegExp(/^[+-]?\d+(\.\d+)?$/);
        if (!reg.test(inputValue) || parseFloat(inputValue) <= 0) {
          terror += 1;
          message = "Please Enter Valid Amount!";
        } else if (parseFloat(inputValue) <= 0) {
          terror += 1;
          message = "Charity/Marketing Fee Can Not Be Zero!";
        } else {
          message = "";
        }
        break;
      case "charityAddr":
        if (inputValue === "") {
          terror += 1;
          message = "Please Input Charity/Marketing Address!";
        } else {
          message = "";
        }
        break;
      default:
        terror += 0;
        break;
    }

    if (terror > 0) {
      setError({ ...error, [input]: message });
      return false;
    } else {
      setError({ ...error, [input]: "" });
      return true;
    }
  };

  const checkLiquidityTokenAllValidation = () => {
    let terror = 0;
    var reg;
    Object.keys(value).forEach((key) => {
      let inputValue;
      switch (key) {
        case "name":
          if (value[key] === "") {
            terror += 1;
          }
          break;
        case "symbol":
          if (value[key] === "") {
            terror += 1;
          }
          break;
        case "supply":
          inputValue = parseFloat(value[key]);
          reg = new RegExp(/^[+-]?\d+(\.\d+)?$/);
          if (!reg.test(inputValue) || parseFloat(inputValue) <= 0) {
            terror += 1;
          } else if (parseFloat(inputValue) <= 0) {
            terror += 1;
          }
          break;
        case "liquidityFee":
          inputValue = parseFloat(value[key]);
          reg = new RegExp(/^[+-]?\d+(\.\d+)?$/);
          if (!reg.test(inputValue) || parseFloat(inputValue) <= 0) {
            terror += 1;
          } else if (parseFloat(inputValue) <= 0) {
            terror += 1;
          }
          break;
        case "yieldFee":
          inputValue = parseFloat(value[key]);
          reg = new RegExp(/^[+-]?\d+(\.\d+)?$/);
          if (!reg.test(inputValue) || parseFloat(inputValue) <= 0) {
            terror += 1;
          } else if (parseFloat(inputValue) <= 0) {
            terror += 1;
          }
          break;
        case "charityFee":
          inputValue = parseFloat(value[key]);
          reg = new RegExp(/^[+-]?\d+(\.\d+)?$/);
          if (!reg.test(inputValue) || parseFloat(inputValue) <= 0) {
            terror += 1;
          } else if (parseFloat(inputValue) <= 0) {
            terror += 1;
          }
          break;
        case "charityAddr":
          if (value[key] === "") {
            terror += 1;
          }
          break;
        default:
          terror += 0;
          break;
      }
    });
    if (terror > 0) {
      return false;
    } else {
      return true;
    }
  };

  const onChangeInput = (e) => {
    e.preventDefault();
    checkLiquidityTokenValidation(e.target.name, e.target.value);
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  const handleCreateLiquidityToken = async (e) => {
    e.preventDefault();
    let check = checkLiquidityTokenAllValidation();
    if (check) {
      try {
        setCreateLoading(true);

        window.web3 = new Web3(window.ethereum);
        let accounts = await window.ethereum.request({
          method: "eth_accounts",
        });

        if (accounts.length === 0) {
          toast.error("Error! Connect Wallet!");
          setCreateLoading(false);
          return;
        }

        const liquidityTokenFactoryContract = new window.web3.eth.Contract(liquidityAbi, liquidityTokenFactoryAddr);

        const feeInEther = 3; //token creation fees
        const feeInWei = window.web3.utils.toWei(feeInEther.toString(), 'ether');

        const txResult = await liquidityTokenFactoryContract.methods.create(
          value["name"],
          value["symbol"],
          value["supply"] + "0".repeat(9),
          routeraddress,
          value["charityAddr"],
          value["yieldFee"],
          value["liquidityFee"],
          value["charityFee"]
        ).send({
          from: accounts[0],
          value: feeInWei
        });

        console.log("Transaction Result:", txResult);

        const receipt = await window.web3.eth.getTransactionReceipt(txResult.transactionHash);
        const tokenAddress = receipt.logs.find(log => log.topics[0] === window.web3.utils.keccak256('OwnershipTransferred(address,address)')).address;

        if (receipt && receipt.status) {
          toast.success("Liquidity Token Created Successfully!");
          setValue({
            name: "",
            symbol: "",
            supply: "",
            yieldFee: "",
            liquidityFee: "",
            charityAddr: "",
            charityFee: "",
          });
          setCreateLoading(false);
          history.push(`/token-details?addr=${tokenAddress}`);
        } else {
          toast.error("Transaction failed!");
        }

      } catch (err) {
        console.error("Error creating liquidity token:", err);
        toast.error("Error! Check console for details.");
      } finally {
        setCreateLoading(false);
      }
    } else {
      toast.error("Error! Please fill out all fields correctly.");
    }
  };

  return (
    <div className={`tab-pane active mt-3`} role="tabpanel" id="step1">
      <div className="row">
        <div className="col-12 col-md-6 mb-0">
          <div className="">
            <label>
              Name<span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              onChange={(e) => onChangeInput(e)}
              value={value.name}
              type="text"
              name="name"
              placeholder="Ex: Ethereum"
            />
            <small className="text-danger">{error.name}</small>
            <br />
          </div>
        </div>
        <div className="col-12 col-md-6 mb-0">
          <div className="">
            <label>
              Symbol<span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              onChange={(e) => onChangeInput(e)}
              value={value.symbol}
              type="text"
              name="symbol"
              placeholder="Ex: ETH"
            />
            <small className="text-danger">{error.symbol}</small>
            <br />
          </div>
        </div>
        <div className="col-12 mb-0">
          <div className="">
            <label>
              Total Supply<span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              onChange={(e) => onChangeInput(e)}
              value={value.supply}
              type="number"
              name="supply"
              placeholder="Ex: 1000000000000000"
            />
            <small className="text-danger">{error.supply}</small>
            <br />
          </div>
        </div>
        <div className="col-12 col-md-6 mb-0">
          <div className="">
            <label>
              Transaction fee to generate yield(%)
              <span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              onChange={(e) => onChangeInput(e)}
              value={value.yieldFee}
              type="number"
              name="yieldFee"
              placeholder="Ex: 1"
            />
            <small className="text-danger">{error.yieldFee}</small>
            <br />
          </div>
        </div>
        <div className="col-12 col-md-6 mb-0">
          <div className="">
            <label>
              Transaction fee to generate liquidity (%)
              <span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              onChange={(e) => onChangeInput(e)}
              value={value.liquidityFee}
              type="number"
              name="liquidityFee"
              placeholder="Ex: 1"
            />
            <small className="text-danger">{error.liquidityFee}</small>
            <br />
          </div>
        </div>
        <div className="col-12 col-md-6 mb-0">
          <div className="">
            <label>
              Charity/Marketing address <span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              onChange={(e) => onChangeInput(e)}
              value={value.charityAddr}
              type="text"
              name="charityAddr"
              placeholder="Ex: 0x187E88D7A462A4fd40cAb233B25ED13F43386F07"
            />
            <small className="text-danger">{error.charityAddr}</small>
            <br />
          </div>
        </div>
        <div className="col-12 col-md-6 mb-0">
          <div className="">
            <label>
              Charity/Marketing percent (%)
              <span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              onChange={(e) => onChangeInput(e)}
              value={value.charityFee}
              type="number"
              name="charityFee"
              placeholder="Ex: 1"
            />
            <small className="text-danger">{error.charityFee}</small>
            <br />
          </div>
        </div>
      </div>
      <ul className="list-inline text-center">
        <li>
          <Button
            type="button"
            className="default-btn"
            loading={createloading}
            onClick={(e) => handleCreateLiquidityToken(e)}
          >
            Create Token
          </Button>
        </li>
      </ul>
    </div>
  );
}
