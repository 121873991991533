export const currencies = {
  56: [ // Binance Smart Chain mainnet
    { address: "0x0000000000000000000000000000000000000000", symbol: "BNB" },
    { address: "0xe9e7cea3dedca5984780bafc599bd69add087d56", symbol: "BUSD" },
    { address: "0x55d398326f99059ff775485246999027b3197955", symbol: "USDT" },
  ],
  97: [ // Binance Smart Chain testnet
    { address: "0x0000000000000000000000000000000000000000", symbol: "BNB" },
    { address: "0xe9e7cea3dedca5984780bafc599bd69add087d56", symbol: "BUSD" },
    { address: "0x55d398326f99059ff775485246999027b3197955", symbol: "USDT" },
  ],
  3889: [ // KalyChain Testnet
    { address: "0x0000000000000000000000000000000000000000", symbol: "KLC" },
    { address: "0xC2AFb6EFca0F6b10f3da80bEc20Dc8DE0DdB689D", symbol: "DAI" },
    { address: "0x37540F0cC489088c01631138Da2E32cF406B83B8", symbol: "USDT" },
  ],
  3888: [ // KalyChain 
    { address: "0x0000000000000000000000000000000000000000", symbol: "KLC" },
    { address: "0xC2AFb6EFca0F6b10f3da80bEc20Dc8DE0DdB689D", symbol: "DAI" },
    { address: "0x37540F0cC489088c01631138Da2E32cF406B83B8", symbol: "USDT" },
  ],
  default: [
    { address: "0x0000000000000000000000000000000000000000", symbol: "BNB" },
    { address: "0xe9e7cea3dedca5984780bafc599bd69add087d56", symbol: "BUSD" },
    { address: "0x55d398326f99059ff775485246999027b3197955", symbol: "USDT" },
  ],
};