export const trimAddress = (addr) => {
  return `${addr.substring(0, 6)}...${addr.substring(addr.length - 4)}`;
};

export const coinArray = {
    97: "BNB",
    84531: "ETH",
    56: "BNB",
    3889: "KLC",
    3888: "KLC"
}

export const convertDay = (mins) => {
  if (parseInt(mins) < 60)
    return mins + " mins";
  else if (parseInt(mins) < 1440)
    return Math.floor(mins/60) + " hrs " + (mins % 60) + " mins";
  else if (parseInt(mins) < 1440) {
    let days = Math.floor(mins/1440);
    let hours = Math.floor((mins - days * 1440) / 60);
    let minutes = mins % 60;
    return days + " days " + hours + " hours " +  minutes + " mins";
  }
}

export const contract = {
  84531: {
    poolfactory:      "0x86841b1Edc2e85188463b04294f969B263C41eA2",
    poolmanager:      "0x0c70E854F57b38561E72f82e4Fd29eE696441fE9",
    routeraddress:    "0x48ec46497ad59acaba41545363cf36fe77786fa7",
    multicallAddress: "0x18540e2a38e3f7eae2a543b572e80304c70965a3",
    lockAddress:      "0x1611C1660cC0A59c6a7567Cc50f67A25C0b00bb8",
    routername:       "Pancakeswap",
    feeReceiver:      "0xCE035af21b4697101Aaf12C261bc6C79b0e38271",
    dividendTracker:  "0xa2532b333215a3330a79609bba0d6a21c9d188f1",
    standardTokenFactory: "0x24E2DE036eBF5E582E50D54C63B122966A24BEa0", 
  },
  56: {
    poolfactory:      "0x271d6CB5598BD8a2bBd76330C1893bBaCAB55B25",
    poolmanager:      "0xcE4fE3C34159cB2Dd31018f58361051261800F33",
    routeraddress:    "0xac208907d258637f0b616a66b88cccd872da49b3",
    multicallAddress: "0xB0fbf7b50b085dEE1C6393157953e69a18a48CdF",
    lockAddress:      "0x98214D358F7EFbD36a490167616D09Ed9BEf0De1",
    routername:       "PancakeSwap",
    feeReceiver:      "0x8a7198c128587E89a8d21cfFe5F1e3ED38FA7be5",
    dividendTracker:  "0xE24223C39EC5Fa06adaFEbDCEF93621677eF681F",
    standardTokenFactory: "0x24E2DE036eBF5E582E50D54C63B122966A24BEa0",  
  },
   97: {
    poolfactory:      "0x3b025773c5a4FB78B54Bf9AC55638304781503F0",
    poolmanager:      "0x43fb080E4cc7f76b1E93168A0aFEc924F7f8Ae07",
    routeraddress:    "0xD99D1c33F9fC3444f8101754aBC46c52416550D1",
    multicallAddress: "0x6e5bb1a5ad6f68a8d7d6a5e47750ec15773d6042",
    lockAddress:      "0x51D80DD31446868d6fdCCdc5908E5BB34032d2C6",
    routername:       "PancakeSwap",
    feeReceiver:      "0x5b4a58806086fc98885Ee57ADe4db240D4ae4e97",
    dividendTracker:  "0x5c7f05a5fb26adeb304b8a8695aabc9b6e7ca037",
    standardTokenFactory: "0x24E2DE036eBF5E582E50D54C63B122966A24BEa0",    
  },
   3889: {
    poolfactory:      "0xBDDd370444F089eb223B94dD6Ec769Ad30782026", 
    poolmanager:      "0x91Cad6C40BDa2E4Ee79c9E0058CC9b0c485b2ddA",
    routeraddress:    "0x26a7f725F5cb11FC04e63d2D451F21BC8ACFC772", //KalySwap Testnet Router
    multicallAddress: "0xb1a656844241f5a7f85A932A1621C604Fe1c932c",
    lockAddress:      "0x1e2f2D02fd9965057e3255d08f2Fcb963Ffa87fa",
    routername:       "KalySwap",
    feeReceiver:      "0x12BA3F424d630A583BdBCa56b0c1A0a7C1d7D66e",
    dividendTracker:  "0xa617E409d4aBd52D5B0aD91801fd921097Cc9934", //BabyToken
    standardTokenFactory: "0x24E2DE036eBF5E582E50D54C63B122966A24BEa0",
  },
   3888: {
    poolfactory:      "0xCab9c004302a5eA38DC3B3B1ba68a7E7Bf691597", 
    poolmanager:      "0x45840c1DA7cBd15C2f7A8DD170Fc1b25674d30d9",
    routeraddress:    "0xA39723dC2713C9eECe0CecC74AC519Aa2dFB4206", //KalySwap Router
    multicallAddress: "0xb815866b0305fb376198a788641aE7B104d3A93F",
    lockAddress:      "0xc800Cdede769B30cc547D5178cCF72DEfC237317",
    routername:       "KalySwap",
    feeReceiver:      "0x12BA3F424d630A583BdBCa56b0c1A0a7C1d7D66e",
    dividendTracker:  "0xa617E409d4aBd52D5B0aD91801fd921097Cc9934", //BabyToken
    tokenFactory:     "0xA1823E8539B2b2741DD6Dd6c852D08E3c84aE31A", 
    liquidityFactory: "0xAA33927C37a4f9Ff643fc48C1f1450caf89380c6",
  },
  default: {
    poolfactory:      "0xCab9c004302a5eA38DC3B3B1ba68a7E7Bf691597", 
    poolmanager:      "0x91Cad6C40BDa2E4Ee79c9E0058CC9b0c485b2ddA",
    routeraddress:    "0x26a7f725F5cb11FC04e63d2D451F21BC8ACFC772", //KalySwap Testnet Router
    multicallAddress: "0xb1a656844241f5a7f85A932A1621C604Fe1c932c",
    lockAddress:      "0x1e2f2D02fd9965057e3255d08f2Fcb963Ffa87fa",
    routername:       "KalySwap",
    feeReceiver:      "0x12BA3F424d630A583BdBCa56b0c1A0a7C1d7D66e",
    dividendTracker:  "0xa617E409d4aBd52D5B0aD91801fd921097Cc9934",
    tokenFactory:     "0x24E2DE036eBF5E582E50D54C63B122966A24BEa0",  
  },
};

// WKLC  0x069255299Bb729399f3CECaBdc73d15d3D10a2A3
// WKLC/USDT Pair 0xf9Cb1278EA82c9a24ab9E27CD66307C5577D3F60
// Kaly Mainnet
// ProxyAdmin 0xd8c90b19f0A7cC325520bfB6363867B202eA7e59
// FairPool 0x710cd6cF46684a6837fb10CDba11aa2Ddccb787C
// PresalePool 0xDE82F63F1A052beFac2853f604Ea3DEFb092fDd2
// PrivatePool 0x7E0da3F5060E0f2273a97dD67A23F0BB7d463806
// PoolManager 0x501957E9fBE93D0d82f4215D4F1E13C35cE74744
// PoolFactory 0xF1e7be35Ce574B61C4514166C64071D92f20b981
// TokenLocker 0xc800Cdede769B30cc547D5178cCF72DEfC237317

// PoolManager Proxy 0xB1f1Cdf662E31f10290311e18A61147ad5332afD
// 0x485cc955000000000000000000000000069255299Bb729399f3CECaBdc73d15d3D10a2A3000000000000000000000000f9Cb1278EA82c9a24ab9E27CD66307C5577D3F60

// PoolFactoy Proxy 0xCab9c004302a5eA38DC3B3B1ba68a7E7Bf691597
// 0x035a1d4b000000000000000000000000DE82F63F1A052beFac2853f604Ea3DEFb092fDd20000000000000000000000007E0da3F5060E0f2273a97dD67A23F0BB7d46380600000000000000000000000045840c1DA7cBd15C2f7A8DD170Fc1b25674d30d9000000000000000000000000710cd6cF46684a6837fb10CDba11aa2Ddccb787C000000000000000000000000000000000000000000000000000000000000000100000000000000000000000000000000000000000000000000038d7ea4c6800000000000000000000000000000000000000000000000000000071afd498d000000000000000000000000000000000000000000000000000000038d7ea4c6800000000000000000000000000000000000000000000000000000038d7ea4c68000000000000000000000000000000000000000000000000000000aa87bee53800000000000000000000000000000000000000000000000000000000000000003e80000000000000000000000000000000000000000000000000000000000000001


//KLC Testnet
//PresalePool: 0x6144fB217479a599168b4D88b03db5d86989489c

//Proxy data
//0x6144fB217479a599168b4D88b03db5d86989489c
//0x0Fd77811326bc4645a57d90594aa2cc9AF91bC4A
//0x10E0e568eB0B7fB68E71b78E8864178d435d5874
//0xaFD6d69205fB2E13E83A5012Ef0C4B00A1c31112
//1
//1000000000000000
//2000000000000000
//1000000000000000
//1000000000000000
//3000000000000000
//1000
//true


// BSC
// MultiCall:       0x6e5bb1a5ad6f68a8d7d6a5e47750ec15773d6042
// BabyToken:       0x5c7f05a5fb26adeb304b8a8695aabc9b6e7ca037
// PresalePool:     0x1b03d864c4a93ef2ae2748bc8949017825809d64
// PrivatePool:     0x368a017dcfa89f7a1db1c37b145791caf40cfd53  

// FairPool:        0x474959a744be23595de1d05648a71d5815472a8d
// TokenLocker:     0x654a68baf4a4dbab4af5548bbbed910af40078ad

// ProxyAdmin:      0xb0d5f7bdf5aec4f953cf956a09432cfd004e0e25

// PoolManager Im:  0xf912e9FdB436C25ABE3B400435990F992f677527     
  // Param: 0xae13d989dac2f0debff460ac112a837c89baa7cd, 0x337610d27c682E347C9cD60BD4b3b107C9d34dDd
  // 0x485cc955000000000000000000000000ae13d989dac2f0debff460ac112a837c89baa7cd000000000000000000000000337610d27c682e347c9cd60bd4b3b107c9d34ddd
// Upgradable:      0xc5027079d74def9644f605fe4b65adc5340585ef

// PoolFactory Im:  0x8dd81cfd8dee5e109804d196a722b9c018d9569a
  // Param:         
  // 0x1b03d864c4a93ef2ae2748bc8949017825809d64 PresalePool
  // 0x368a017dcfa89f7a1db1c37b145791caf40cfd53 PrivatePool
  // 0xc5027079d74def9644f605fe4b65adc5340585ef TransProxy1
  // 0x474959a744be23595de1d05648a71d5815472a8d Fairpool
  // 1
  // 1000000000000000
  // 2000000000000000
  // 1000000000000000
  // 1000000000000000
  // 3000000000000000
  // 1000
  // true

// Upgradable:      0xe90321bf32a9c1ee284cdd849ed4c37da940d54c

// 0x035a1d4b0000000000000000000000001b03d864c4a93ef2ae2748bc8949017825809d64000000000000000000000000368a017dcfa89f7a1db1c37b145791caf40cfd53000000000000000000000000c5027079d74def9644f605fe4b65adc5340585ef000000000000000000000000474959a744be23595de1d05648a71d5815472a8d000000000000000000000000000000000000000000000000000000000000000100000000000000000000000000000000000000000000000000038d7ea4c6800000000000000000000000000000000000000000000000000000071afd498d000000000000000000000000000000000000000000000000000000038d7ea4c6800000000000000000000000000000000000000000000000000000038d7ea4c68000000000000000000000000000000000000000000000000000000aa87bee53800000000000000000000000000000000000000000000000000000000000000003e80000000000000000000000000000000000000000000000000000000000000001
// 0x035a1d4b0000000000000000000000006144fb217479a599168b4d88b03db5d86989489c0000000000000000000000000fd77811326bc4645a57d90594aa2cc9af91bc4a00000000000000000000000010e0e568eb0b7fb68e71b78e8864178d435d5874000000000000000000000000afd6d69205fb2e13e83a5012ef0c4b00a1c31112000000000000000000000000000000000000000000000000000000000000000100000000000000000000000000000000000000000000000000038d7ea4c6800000000000000000000000000000000000000000000000000000071afd498d000000000000000000000000000000000000000000000000000000038d7ea4c6800000000000000000000000000000000000000000000000000000038d7ea4c680000000000000000000000000000000000000000000000000000001a055690d9db800000000000000000000000000000000000000000000000000000000000003e80000000000000000000000000000000000000000000000000000000000000001

// @zappingpentester


//-----Decoded View--------------- TransparentProxy 1 Perams for ProxyManager are wklc and usdt addy
//Arg [0] : _logic (address): 0xf912e9FdB436C25ABE3B400435990F992f677527 -- PoolManager
//Arg [1] : admin_ (address): 0xb0D5f7Bdf5aeC4f953cF956A09432cfd004E0e25 -- ProxyAdmin
//Arg [2] : _data (bytes): 0x485cc955000000000000000000000000ae13d989dac2f0debff460ac112a837c89baa7cd000000000000000000000000337610d27c682e347c9cd60bd4b3b107c9d34ddd

// WKLC 0x069255299Bb729399f3CECaBdc73d15d3D10a2A3
// USDT 0x8354f6c2188e5fc571f1bcb1c4b4ea8d0eb24183
// Pair 0xFAcbb8bEa3cA46d550448aaa26D48aB8a182c4C0
// PoolManager proxy: 0x91Cad6C40BDa2E4Ee79c9E0058CC9b0c485b2ddA
//0x485cc955000000000000000000000000069255299Bb729399f3CECaBdc73d15d3D10a2A30000000000000000000000008354f6c2188e5fc571f1bcb1c4b4ea8d0eb24183


//0x035a1d4b000000000000000000000000b71909BB028ABA66b01Afe670B75f7873249a219000000000000000000000000F1C1fdAdaeb5ffFB01557D2963d42eBBa370933400000000000000000000000017194303Bf397327ABe4Ad8601C08565bc0F2A83000000000000000000000000afd6d69205fb2e13e83a5012ef0c4b00a1c31112000000000000000000000000000000000000000000000000000000000000000100000000000000000000000000000000000000000000000000038d7ea4c6800000000000000000000000000000000000000000000000000000071afd498d000000000000000000000000000000000000000000000000000000038d7ea4c6800000000000000000000000000000000000000000000000000000038d7ea4c680000000000000000000000000000000000000000000000000000001a055690d9db800000000000000000000000000000000000000000000000000000000000003e80000000000000000000000000000000000000000000000000000000000000001

// PoolFactory 0x20A19e10173538278D6E6A1C7de55Bc03556B849 
// Presalepool 0xb71909BB028ABA66b01Afe670B75f7873249a219
// Privayepool 0xF1C1fdAdaeb5ffFB01557D2963d42eBBa3709334
// PoolManager proxy: 0x91Cad6C40BDa2E4Ee79c9E0058CC9b0c485b2ddA
// Fairpool 0x17194303Bf397327ABe4Ad8601C08565bc0F2A83

//PoolFactory 0xBDDd370444F089eb223B94dD6Ec769Ad30782026