import ethCoin from "../images/coins/eth-coin.png";
import bnbCoin from "../images/coins/bnb-coin.png";
import klcCoin from "../images/coins/klc-coin.png";

export const supportNetwork = {

  3889: {
    name: "KalyChain Testnet",
    chainId: 3889,
    rpc: "https://testnetrpc.kalychain.io/rpc",
    image: klcCoin,
    symbol: "KLC",
  },
  3888: {
    name: "KalyChain",
    chainId: 3888,
    rpc: "https://rpc.kalychain.io/rpc",
    image: klcCoin,
    symbol: "KLC",
  },
  97: {
    name: "BNB Chain Testnet",
    chainId: 97,
    rpc: "https://bsc-testnet.publicnode.com",
    image: bnbCoin,
    symbol: "BNB",
  },
 
  default: {
    name: "BNB Chain Testnet",
    chainId: 97,
    rpc: "https://bsc-testnet.publicnode.com",
    image: bnbCoin,
    symbol: "BNB",
  },
};

export const networkLists = [
  { code: 0, chainId: 97, label: 'BNB Chain Testnet', image: bnbCoin },
  { code: 0, chainId: 56, label: 'BNB Chain', image: bnbCoin },
  { code: 0, chainId: 84531, label: 'BASE Chain', image: ethCoin },
  { code: 0, chainId: 3889, label: 'KalyChain Testnet', image: klcCoin },
  { code: 0, chainId: 3888, label: 'KalyChain', image: klcCoin },

]


export const RPC_URLS = {
  97: "https://data-seed-prebsc-1-s1.binance.org:8545",
  56: "https://bsc-dataseed1.ninicoin.io",
  84531: "https://base-goerli.public.blastapi.io",
  3889: "https://testnetrpc.kalychain.io/rpc",
  3888: "https://rpc.kalychain.io/rpc"
};
