import React, { useState } from "react";
import { Link } from "react-router-dom";
import { usePadStatus } from "./helper/useStats";

import logo from "../../images/logo.png";
import cex from "../../images/cex.png";
import klc from "../../images/klc-coin.png";

export default function Home() {
  const stats = usePadStatus();

  return (
    <React.Fragment>
      <section id="home" className="project-area pt-0">
        <div className="container px-2 px-sm-5 py-5">
          <div className="text-center mt-5">
            <h3 className="text-uppercase">
             KalyPad
            </h3>
            <p>
             Powered by KalyChain
            </p>
          </div>
          <div className="text-center mt-5">
  <Link to="/presale">
    <button className="btn">Create IDO</button>
  </Link>
  
</div>

          <div className="row my-5">
            <div className="col-12 col-md-6 col-xl-3">
              <div className="card text-center my-2 pt-0 pb-4">
                <h3>${stats.totalLiquidityRaised}</h3>
                <p className="">Total Liquidity Raised</p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-3">
              <div className="card text-center my-2 pt-0 pb-4">
                <h3>{stats.totalProjects}</h3>
                <p>Total Projects</p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-3">
              <div className="card text-center my-2 pt-0 pb-4">
                <h3>{stats.totalParticipants}</h3>
                <p>Total Participants</p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-3">
              <div className="card text-center my-2 pt-0 pb-4">
                <h3>${stats.totalValueLocked}</h3>
                <p>Total Value Locked</p>
              </div>
            </div>
          </div>

          
          <div className="d-flex flex-column justify-content-center align-items-center mt-5 text-center">
            <h3 className="text-uppercase col-12 col-md-10 text-center">
              Our Growing KALYSSI Ecosystem
            </h3>
            <p className="col-12 col-md-10 text-center">
            Decentralized Finance for the World.
            </p>
          </div>
          <div className="row my-5">
  <div className="col-12 col-md-6 col-xl-6">
    <a href="https://kalychain.io/" target="_blank" rel="noopener noreferrer" className="card-link no-link">
      <div className="card d-flex flex-column justify-content-center align-items-center text-center my-2 pt-4 pb-4">
        <img src={klc} alt="KalyChain Logo" width="50px" />
        <h4 className="my-2">KalyChain</h4>
        <p className="">Layer 1 EVM Blockchain.</p>
      </div>
    </a>
  </div>

  <div className="col-12 col-md-6 col-xl-6">
    <a href="https://app.kalyswap.io/" target="_blank" rel="noopener noreferrer" className="card-link no-link">
      <div className="card d-flex flex-column justify-content-center align-items-center text-center my-2 pt-4 pb-4">
        <img src={logo} alt="KalySwap Logo" width="50px" />
        <h4 className="my-2">KalySwap</h4>
        <p className="">Decentralized Trading Platform.</p>
      </div>
    </a>
  </div>

  <div className="col-12 col-md-6 col-xl-6">
    <a href="https://kalypay.com/" target="_blank" rel="noopener noreferrer" className="card-link no-link">
      <div className="card d-flex flex-column justify-content-center align-items-center text-center my-2 pt-4 pb-4">
        <img src={cex} alt="KalyPay Logo" width="50px" />
        <h4 className="my-2">KalyPay</h4>
        <p className="">Digital Payment Solution</p>
      </div>
    </a>
  </div>

  <div className="col-12 col-md-6 col-xl-6">
    <a href="https://kalyexchange.com/" target="_blank" rel="noopener noreferrer" className="card-link no-link">
      <div className="card d-flex flex-column justify-content-center align-items-center text-center my-2 pt-4 pb-4">
        <img src={cex} alt="Cex Logo" width="50px" />
        <h4 className="my-2">KalyExchange</h4>
        <p className="">The Most Liquid CEX in Crypto.</p>
      </div>
    </a>
  </div>
</div>



          {/* Second Box */}
          <div className="d-flex flex-column justify-content-center align-items-center mt-5 text-center">
            <h3 className="text-uppercase col-12 col-md-10 text-center">
              A Suite of Tools for Token Sales.
            </h3>
            <p className="col-12 col-md-10 text-center">
              A suite of tools were built to help you create your own tokens and
              launchpads in a fast, simple and cheap way, with no prior code
              knowledge required and 100% decentralized!
            </p>
          </div>
          <div className="row my-5">
         

            
            <div className="col-12 col-md-6 col-xl-3">
              <div className="card d-flex flex-column justify-content-center align-items-center text-center my-2 pt-4 pb-4">
                <img src={logo} alt="Brand Logo" width="50px" />
                <h4 className="my-2">Standard</h4>
                <p className="">
                  Create Standard KRC20/ERC20 Tokens on KalyChain.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-3">
              <div className="card d-flex flex-column justify-content-center align-items-center text-center my-2 pt-4 pb-4">
                <img src={logo} alt="Brand Logo" width="50px" />
                <h4 className="my-2">Deflationary</h4>
                <p>
                  Create Deflationary Tokens with Tax and/or Charity Functions.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-3">
              <div className="card d-flex flex-column justify-content-center align-items-center text-center my-2 pt-4 pb-4">
                <img src={logo} alt="Brand Logo" width="50px" />
                <h4 className="my-2">Customization</h4>
                <p>Create a token sale for your own custom token easily.</p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-3">
              <div className="card d-flex flex-column justify-content-center align-items-center text-center my-2 pt-4 pb-4">
                <img src={logo} alt="Brand Logo" width="50px" />
                <h4 className="my-2">Launchpad</h4>
                <p>
                  Use the token you mint to create a launchpad with just a few
                  clicks
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-3">
              <div className="card d-flex flex-column justify-content-center align-items-center text-center my-2 pt-4 pb-4">
                <img src={logo} alt="Brand Logo" width="50px" />
                <h4 className="my-2">Branding</h4>
                <p className="">
                  Adding logo, social links, description, listing on KalyPad.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-3">
              <div className="card d-flex flex-column justify-content-center align-items-center text-center my-2 pt-4 pb-4">
                <img src={logo} alt="Brand Logo" width="50px" />
                <h4 className="my-2">Management</h4>
                <p>
                  The portal to help you easily update content for your
                  launchpad.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-3">
              <div className="card d-flex flex-column justify-content-center align-items-center text-center my-2 pt-4 pb-4">
                <img src={logo} alt="Brand Logo" width="50px" />
                <h4 className="my-2">Community</h4>
                <p>Promote your launchpad to thousands of buyers on KalyPad.</p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-3">
              <div className="card d-flex flex-column justify-content-center align-items-center text-center my-2 pt-4 pb-4">
                <img src={logo} alt="Brand Logo" width="50px" />
                <h4 className="my-2">Locking</h4>
                <p>
                  Lock your liquidity at KalyPad after Presale for Investor Safety.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
           <center> <p className="text-center">
            KalyChain . All rights reserved
            </p>
            </center>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
